import React, { useRef, useEffect, useState } from 'react';
import { globalStore } from '~/store';
import { getHash } from '~/utils';
interface ClientRects {
  [key: string]: DOMRect[];
}

interface SerializedClientRects {
  [key: string]: {
    top: number;
    right: number;
    bottom: number;
    left: number;
    width: number;
    height: number;
    x: number;
    y: number;
    hash: string;
  }[];
}

const serializeRects = async (rects: ClientRects): Promise<SerializedClientRects> => {
  const serialized: SerializedClientRects = {};
  for (const key in rects) {
    if (rects.hasOwnProperty(key)) {
      const rectsArray = await Promise.all(
        rects[key].map(async (rect) => {
          const rectObj = {
            top: rect.top,
            right: rect.right,
            bottom: rect.bottom,
            left: rect.left,
            width: rect.width,
            height: rect.height,
            x: rect.x,
            y: rect.y,
          };
          const hash = (await getHash(rectObj)).slice(0, 12);
          return { ...rectObj, hash };
        }),
      );
      serialized[key] = rectsArray;
    }
  }
  return serialized;
};

const ClientRectsFingerprint: React.FC = () => {
  const elementRef1 = useRef<HTMLDivElement>(null);
  const elementRef2 = useRef<HTMLDivElement>(null);
  const elementRef3 = useRef<HTMLDivElement>(null);
  const [clientRects, setClientRects] = useState<SerializedClientRects>({});
  const [overallHash, setOverallHash] = useState<string | null>(null);

  useEffect(() => {
    const calculateHash = async (rects: SerializedClientRects) => {
      const rectsString = JSON.stringify(rects);
      return await getHash(rectsString);
    };

    const collectRectsData = async () => {
      const rects: ClientRects = {};

      if (elementRef1.current) {
        rects['Element1'] = Array.from(elementRef1.current.getClientRects()) as DOMRect[];
      }
      if (elementRef2.current) {
        rects['Element2'] = Array.from(elementRef2.current.getClientRects()) as DOMRect[];
      }
      if (elementRef3.current) {
        rects['Element3'] = Array.from(elementRef3.current.getClientRects()) as DOMRect[];
      }

      const serializedRects = await serializeRects(rects);
      setClientRects(serializedRects);

      const overallHashValue = await calculateHash(serializedRects);

      const dataToStore = {
        hash: overallHashValue,
        data: {
          rects: serializedRects,
        },
      };
      setOverallHash(overallHashValue)
      globalStore.set({ ...globalStore.get(), client_rects: dataToStore });
    };

    collectRectsData();
  }, []);

  return (
    <div>
      <h2 className="text-3xl font-medium mb-5 flex items-center gap-2">Client Rects <span className="text-sm border px-1">{overallHash?.slice(0, 12)}</span></h2>
      <div className="overflow-auto border p-5 rounded-lg border-slate-200">
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-5">
          <div>
            <span ref={elementRef1} className="block rotate-1 mb-2 text-blue-500 uppercase">
              Element 1
            </span>
            <pre>{JSON.stringify(clientRects['Element1'], null, 2)}</pre>
          </div>
          <div>
            <span ref={elementRef2} className="block mb-2 tracking-tighter -rotate-1 uppercase text-green-500">
              Element 2
            </span>
            <pre>{JSON.stringify(clientRects['Element2'], null, 2)}</pre>
          </div>
          <div>
            <span ref={elementRef3} className="block mb-2 rotate-3 tracking-[.40em] uppercase text-red-500">
              Element 3
            </span>
            <pre>{JSON.stringify(clientRects['Element3'], null, 2)}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientRectsFingerprint;
